import "../styles/user/products.css";

// SeaFish
// import anchovy from "../../assests/user/images/seaFish/anchovy.png";
// import bream from "../../assests/user/images/seaFish/Bream.jpg";
// import croaker from "../../assests/user/images/seaFish/croaker.png";
// import salmon from "../../assests/user/images/seaFish/salmon.png";
// import mackerel from "../../assests/user/images/seaFish/mackerel.jpg";
// import blackPomfret from "../../assests/user/images/seaFish/blackPomfret.jpg";
// import herring from "../../assests/user/images/seaFish/herring.png";
// import ribbon from "../../assests/user/images/seaFish/ribbon.jpg";
// import tuna from "../../assests/user/images/seaFish/tuna.png";
// import milk from "../../assests/user/images/seaFish/milk.png";

// Fresh Water Fish
import fishfingers from "../../assests/user/images/freshWaterFish/FishFingers.png";
import fishnuggets from "../../assests/user/images/freshWaterFish/FishNuggets.png";
import fishsandwich from"../../assests/user/images/freshWaterFish/Fishsandwich.png";
import tawafishfry from "../../assests/user/images/freshWaterFish/tawafishfry.png";
import fishpakoara from "../../assests/user/images/freshWaterFish/fishpakora.png";
import fishpopcorn from "../../assests/user/images/freshWaterFish/fishpopcorn.png";
import fishburger from "../../assests/user/images/freshWaterFish/fishburger.png";
import fishcutlet from "../../assests/user/images/freshWaterFish/fishcutlet.png";
import fishchips from "../../assests/user/images/freshWaterFish/fishchips.png";
import fishtikka from "../../assests/user/images/freshWaterFish/fishtikka.png";
import friedcatfish from "../../assests/user/images/freshWaterFish/oilfryfish.png";
import frenchfries from "../../assests/user/images/freshWaterFish/frenchfries.png";




// Prawns fish

// import atlantic from "../../assests/user/images/prawns/atlantic.jpg";
// import blackTigerShrimp from "../../assests/user/images/prawns/blackTigerShrimp.jpg";
// import northernPrawn from "../../assests/user/images/prawns/northernPrawn.jpg";
// import whiteLegShrimp from "../../assests/user/images/prawns/whiteLegShrimp.jpg";
// import giantTigerShrimp from "../../assests/user/images/prawns/giantTigerShrimp.jpg";
import prawnburger from "../../assests/user/images/prawns/prawnburger.png";
import prawnfries from "../../assests/user/images/prawns/prawnfries.png";
import prawnnuggets from "../../assests/user/images/prawns/prawnnuggets.png";
import prawnsandwich from "../../assests/user/images/prawns/prawnsandwich.png";


// Crab

import crabpakora from "../../assests/user/images/crab/crabpakora.png";
// import mud from "../../assests/user/images/crab/mud.jpg";
// import blueCrab from "../../assests/user/images/crab/blueCrab.jpg";
// import seaCrab from "../../assests/user/images/crab/seaCrab.jpg";
// import stoneCrab from "../../assests/user/images/crab/stoneCrab.png";
import crubcake from "../../assests/user/images/crab/crabcake.png";
import friedcrub from "../../assests/user/images/crab/friedcrab.png";



// Squid
import friedsquid from "../../assests/user/images/squid/friedsquid.png";
import squidnuggets from "../../assests/user/images/squid/squidnuggets.png";
import squidburger from "../../assests/user/images/squid/squidburger.png";
import squidsandwich from "../../assests/user/images/squid/squidsandwich.png";
// import jumbo from "../../assests/user/images/squid/jumbo.jpg";
import squidfries from "../../assests/user/images/squid/squidfries.png";
import squidkebab from "../../assests/user/images/squid/squidkebab.png";

// Pickles & Masala Items

// import fishPickle from "../../assests/user/images/pickled_Masala/fishPickles.png";
// import fishKebabMasala from "../../assests/user/images/pickled_Masala/fishKebabMasala.jpeg";
// import fishKulambu from "../../assests/user/images/pickled_Masala/fishKulambuMasala.jpeg";
// import fish_65 from "../../assests/user/images/pickled_Masala/Fish_65_Masala.jpg";

// Dried Fish
// import driedAnchovy from "../../assests/user/images/driedFish/driedAnchovy.jpg";
// import driedShankara from "../../assests/user/images/driedFish/driedShankaraFish.png";
// import driedPrawn from "../../assests/user/images/driedFish/DriedPrawn.png";
// import driedShell from "../../assests/user/images/driedFish/Driedshell.png";
// import driedCroker from "../../assests/user/images/driedFish/driedCroakerFish.jpg";
// import driedMackrelFish from "../../assests/user/images/driedFish/DriedMackrelFish.png";
// import driedBombayDuckFish from "../../assests/user/images/driedFish/driedBombayDuckFish.png";
// import driedShrimp from "../../assests/user/images/driedFish/driedShrimp.jpg";
// import driedEel from "../../assests/user/images/driedFish/driedEelFish.png";


// React-slick
import React, { useState } from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-scroll";

export const Products = ({ propsProductValue }) => {

    const whatsappNumber = "6366192865";

    const whatsappUrl = `https://wa.me/${whatsappNumber}`;

    const handleChatSubmit = (e) => {
        e.preventDefault();
        window.open(whatsappUrl, "_blank")
    }

    const kilo = " /-";

    // const gram = "- (50 g)";

    // const gram100 = "- (100 g)";



    const productList = {
        // seaFish: [
        //     {
        //         discount: "-34%",
        //         featured: false,
        //         img: anchovy,
        //         stock: "In Stock",
        //         productName: "Anchovy fish",
        //         mainAmount: 250.00,
        //         subAmount: 350.00,
        //         type: kilo,
        //         para: "Anchovies are small, green ﬁsh with blue reﬂections due to a silver-colored longitudinal stripe that runs from the base of the caudal (tail) ﬁn.They range from 2 to 40 centimeters(1 to 15+1⁄2 inches) in adult length, and their more slender ﬁsh in northern populations."
        //     },
        //     {
        //         discount: "-25%",
        //         featured: false,
        //         img: herring,
        //         stock: "In Stock",
        //         productName: "Herring fish",
        //         mainAmount: 200.00,
        //         subAmount: 250.00,
        //         type: kilo,
        //         para: "Herring have a blue-green upper body with silvery sides and are devoid of markings.The body is laterally compressed and the scales along the underside project in a slightly serrated arrangement.Scales are large and easily removed.Their tails are deeply forked and they lack adipose ﬁns."
        //     },
        //     {
        //         discount: "-14%",
        //         featured: true,
        //         img: croaker,
        //         stock: "In Stock",
        //         productName: "Croaker fish",
        //         mainAmount: 150.00,
        //         subAmount: 200.00,
        //         type: kilo,
        //         para: "The Atlantic croaker is a member of the drum and croaker family. It has a slightly elongated, fusiform body with a rounded dorsal proﬁle and nearly straight ventral proﬁle. It has a moderately large and sub terminal mouth, and there are between six and ten tiny chin barbells."
        //     },
        //     {
        //         discount: "-25%",
        //         featured: false,
        //         img: salmon,
        //         stock: "In Stock",
        //         productName: "Salmon fish",
        //         mainAmount: 400.00,
        //         subAmount: 600.00,
        //         type: kilo,
        //         para: "They're found in tributaries of the Paciﬁc and Atlantic Oceans, and most species are anadromous: They are born in streams and rivers, migrate out to the open sea, and then return to freshwater again to reproduce. Salmons have color throughout their lifetimes."
        //     },
        //     {
        //         discount: "-33%",
        //         featured: false,
        //         img: mackerel,
        //         stock: "In Stock",
        //         productName: "Mackerel fish",
        //         mainAmount: 150.00,
        //         subAmount: 250.00,
        //         type: kilo,
        //         para: "Mackerels are rounded and torpedo-shaped, with a slender, keeled tail base, a forked tail,and a row of small inlets behind the dorsal and anal ﬁns. They are carnivorous ﬁshes and feed on plankton, crustaceans, mollusks, ﬁsh eggs, and small ﬁsh."
        //     },
        //     {
        //         discount: "-14%",
        //         featured: true,
        //         img: blackPomfret,
        //         stock: "In Stock",
        //         productName: "Black Pomfret Fish",
        //         mainAmount: 600.00,
        //         subAmount: 800.00,
        //         type: kilo,
        //         para: "The black pomfret has a compressed deep body with dorsal and ventral proﬁles being equally convex.This species has small dark grey scales which cover the entirety of the body.The fork measurement of Black Pomfret is commonly 30 cm but can also range up to 75 cm in length."
        //     },
        //     {
        //         discount: "-50%",
        //         featured: false,
        //         img: bream,
        //         stock: "In Stock",
        //         productName: "Bream fish",
        //         mainAmount: 130.00,
        //         subAmount: 200.00,
        //         type: kilo,
        //         para: "The bream is a medium-sized ﬁsh and a member of the carp family, so displays the typical carp shape. It is a bottom-feeding ﬁsh that preys on worms, snails and pea mussels. It lives in large ponds, lakes and slow-moving rivers where it congregates in quite large shoals."
        //     },
        //     {
        //         discount: "-50%",
        //         featured: false,
        //         img: ribbon,
        //         stock: "In Stock",
        //         productName: "Ribbon fish",
        //         mainAmount: 150.00,
        //         subAmount: 200.00,
        //         type: kilo,
        //         para: "A ribbon-shaped ﬁsh, very thin from side to side, the oarﬁsh may grow to a length of about 9 meters (30.5 feet) and a weight of 300 kg (660 pounds).It is shiny silver in color, with long, red, oarlike rises as a manelike crest on top of the head."
        //     },
        //     {
        //         discount: "-14%",
        //         featured: true,
        //         img: tuna,
        //         stock: "In Stock",
        //         productName: "Tuna fish",
        //         mainAmount: 100.00,
        //         subAmount: 200.00,
        //         type: kilo,
        //         para: "Tuna are remarkable and impressive wild animals. The Atlantic blueﬁn can reach ten feet in length and weigh as much as 2,000 pounds (more than a horse).Their specialized body shape, ﬁns, and scales enable some species of tuna to swim as fast as 43 miles per hour."
        //     },
        //     {
        //         discount: "-25%",
        //         featured: false,
        //         img: milk,
        //         stock: "In Stock",
        //         productName: "Milk fish",
        //         mainAmount: 200.00,
        //         subAmount: 300.00,
        //         type: kilo,
        //         para: "Milkﬁsh have a spindle-like shape that is moderately compressed, smooth and streamlined. Its body color is silvery on the belly and sides, grading to olive green or blue on the back. The wild catch is limited to just slightly more than one percent of total world production."
        //     }
        // ],
        freshWater: [
            {
                discount: "-34%",
                featured: false,
                img: fishnuggets,
                stock: "In Stock",
                productName: "Fish nuggets",
                mainAmount: 49.00,
                subAmount: 119.00,
                type: kilo,
                para: " Each nugget is fried to a golden brown, delivering a satisfying crunch and juicy, flavorful fish insideEnjoy them with your favorite dipping sauces for a delightful experience! Ideal for a quick snack or a satisfying meal, our Fish Nuggets are also sustainably sourced, reflecting our commitment to quality and responsible seafood practices."
            },
            {
                discount: "-50%",
                featured: false,
                img: fishfingers,
                stock: "In Stock",
                productName: "Fish fingers",
                mainAmount: 49.00,
                subAmount: 119.00,
                type: kilo,
                para: "Perfect for both kids and adults, they’re quick to prepare and ideal for any meal or snack time. Enjoy Seaboss Fish Fingers with your favorite dipping sauces for a delightful and satisfying seafood experience!Made from high-quality, sustainably sourced fish, our fish fingers are coated in a light, crunchy batter that locks in the tender, flavorful fish."
            },
            {
                discount: "-14%",
                featured: true,
                img: fishsandwich,
                stock: "In Stock",
                productName: "Fish sandwich",
                mainAmount: 49.00,
                subAmount: 100.00,
                type: kilo,
                para: "Topped with crisp lettuce, juicy tomatoes, and our signature tartar sauce, this sandwich is a harmonious blend of flavors and textures. Ideal for a quick lunch or a delicious dinner, the Seaboss Fish Sandwich offers a mouthwatering seafood experience that's both wholesome and indulgent. Enjoy it as a classic or customize with your favorite toppings for a sandwich that’s uniquely yours!."
            },
            {
                discount: "-25%",
                featured: false,
                img: tawafishfry,
                stock: "In Stock",
                productName: "Tawa Fish fry",
                mainAmount: 99.00,
                subAmount: 159.00,
                type: kilo,
                para: "Each bite delivers a delightful crunch and a burst of savory, spiced goodness that captures the essence of authentic seafood cuisine. With its golden, crispy exterior and juicy, flavorful interior, our Tawa Fish Fry is perfect for a satisfying meal or an appetizing snack. Enjoy it with a side of fresh salad or your favorite dipping sauce for a taste sensation that’s truly unforgettable!."
            },
            {
                discount: "-14%",
                featured: true,
                img: fishpakoara,
                stock: "In Stock",
                productName: "Fish Pakora",
                mainAmount: 59.00,
                subAmount: 149.00,
                type: kilo,
                para: "Perfect as an appetizer or a tasty treat, our Fish Pakora is served hot and fresh, often accompanied by a tangy mint chutney or a zesty tamarind saucethe ultimate snack with our Fish Pakora, where succulent pieces of tender fish are enveloped in a spiced chickpea flour batter and fried to golden perfection. Each bite offers a satisfying crunch and a burst of aromatic spices that elevate the delicate flavor of the fish."
            },
            {
                discount: "-14%",
                featured: true,
                img: fishpopcorn,
                stock: "In Stock",
                productName: "Fish Popcorn",
                mainAmount: 59.00,
                subAmount: 150.00,
                type: kilo,
                para: "Delight in the fun and flavor of our Fish Popcorn, where tender pieces of premium fish are seasoned, lightly breaded, and fried to a golden, crispy finish Perfectly paired with your favorite dipping sauces, our Fish Popcorn is a versatile and irresistible treat that’s sure to please seafood lovers of all ages. Enjoy these crunchy, savory bites as a quick snack or an appetizing party starter!."
            },
            {
                discount: "-14%",
                featured: true,
                img: fishburger,
                stock: "In Stock",
                productName: "Fish Burger",
                mainAmount: 99.00,
                subAmount: 159.00,
                type: kilo,
                para: "Treat yourself to the ultimate seafood experience with our Fish Burger, featuring a perfectly seasoned, flaky fish fillet that’s grilled or fried to golden perfection. Nestled between a soft, toasted bun and topped with crisp lettuce, juicy tomatoes, and a dollop of our creamy, tangy tartar sauce, this burger delivers a harmonious blend of textures and flavors."
            },
            {
                discount: "-14%",
                featured: true,
                img: fishcutlet,
                stock: "In Stock",
                productName: "Fish Cutlet",
                mainAmount: 59.00,
                subAmount: 100.00,
                type: kilo,
                para: "Enjoy the delectable taste of our Fish Cutlet, where tender chunks of high-quality fish are blended with a savory mix of herbs, spices, and breadcrumbs, then fried to a crispy, golden-brown finish. Each cutlet offers a delightful crunch on the outside and a juicy, flavorful interior, making it a perfect appetizer, snack, or light meal."
            },
            {
                discount: "-14%",
                featured: true,
                img: fishchips,
                stock: "In Stock",
                productName: "Fish Chips",
                mainAmount: 49.00,
                subAmount: 159.00,
                type: kilo,
                para: "Dive into a classic favorite with our Fish and Chips, featuring tender, flaky white fish coated in a golden, crispy batter. Each piece is cooked to perfection, served alongside perfectly crisped fries that are lightly seasoned for maximum flavor. Accompanied by a side of tangy tartar sauce and a wedge of fresh lemon, this dish offers a timeless taste of comfort and tradition. Enjoy a taste of the coast with every bite!."
            },
            {
                discount: "-14%",
                featured: true,
                img: fishtikka,
                stock: "In Stock",
                productName: "Tandoori Fish Tikka",
                mainAmount: 69.00,
                subAmount: 159.00,
                type: kilo,
                para: "Experience a burst of vibrant flavors with our Tandoori Fish Tikka. Tender chunks of fresh fish are marinated in a blend of aromatic spices, yogurt, and a hint of tangy lemon juice, creating a rich, flavorful coating. Grilled to perfection in a traditional tandoor oven, each bite offers a smoky char and a succulent, juicy texture. Served with a side of cooling mint chutney and crisp, refreshing salad, this dish is a tantalizing fusion of spice and taste that will transport your taste buds straight to the heart of Indian cuisine."
            },
            {
                discount: "-14%",
                featured: true,
                img: friedcatfish,
                stock: "In Stock",
                productName: "Fish Oil Fry",
                mainAmount: 69.00,
                subAmount: 159.00,
                type: kilo,
                para: "Indulge in the crispy delight of our Fish Oil Fry, where succulent pieces of fresh fish are expertly coated in a seasoned batter and fried to golden perfection. The result is a crunchy exterior that gives way to tender, flaky fish inside. Lightly drizzled with a touch of fish oil for a hint of rich, savory depth, this dish is served with a zesty dipping sauce and a sprinkle of fresh herbs. Perfect as a snack or a main course, it’s a deliciously satisfying treat that combines simplicity with exceptional flavor."
            },
            {
                discount: "-14%",
                featured: true,
                img: frenchfries,
                stock: "In Stock",
                productName: "French Fries",
                mainAmount: 29.00,
                subAmount: 89.00,
                type: kilo,
                para: "Enjoy the timeless satisfaction of our classic French Fries. Cut from premium potatoes, these fries are double-fried to achieve the perfect balance of crispy exterior and fluffy interior. Lightly seasoned with just the right amount of salt, they offer a golden-brown crunch in every bite. Served hot and fresh, they’re the perfect accompaniment to any meal or a delicious snack all on their own. For an extra treat, pair them with your choice of dipping sauces."
            }
        ],
        prawn: [
            {
                discount: "-34%",
                featured: false,
                img: prawnburger,
                stock: "In Stock",
                productName: "Prawn Burger",
                mainAmount: 99.00,
                subAmount: 159.00,
                type: kilo,
                para: "Dive into a seafood sensation with our Prawn Burger. Juicy, tender prawns are seasoned with a blend of zesty herbs and spices, then gently folded into a savory patty that's grilled to perfection. Nestled in a lightly toasted brioche bun, the prawn patty is topped with crisp lettuce, ripe tomatoes, and a tangy aioli sauce that adds a creamy kick. Finished with a hint of lemon zest and a sprinkle of fresh chives, this burger offers a delightful burst of flavors with every bite. Served with a side of golden fries or a fresh salad, it's a true taste of the ocean on a bun!."
            },
            {
                discount: "-50%",
                featured: false,
                img: prawnsandwich,
                stock: "In Stock",
                productName: "Prawn Sandwich",
                mainAmount: 99.00,
                subAmount: 159.00,
                type: kilo,
                para: "Experience a taste of coastal delight with our Prawn Sandwich. Succulent, marinated prawns are delicately sautéed to retain their natural sweetness and tender texture. Nestled between slices of crusty, artisanal bread, the prawns are accompanied by a fresh layer of crisp lettuce, juicy tomato slices, and a generous spread of zesty lemon-dill mayo. The sandwich is finished with a hint of avocado for a creamy, rich touch and a sprinkle of microgreens for added freshness. Each bite offers a perfect blend of flavors and textures, making this sandwich a satisfying choice for a light yet indulgent meal. Served with a side of mixed greens or crispy chips for a complete experience."
            },
            {
                discount: "-14%",
                featured: true,
                img: prawnnuggets,
                stock: "In Stock",
                productName: "Prawn Nuggets",
                mainAmount: 99.00,
                subAmount: 199.00,
                type: kilo,
                para: "Indulge in a crispy, flavorful treat with our Prawn Nuggets. Tender, juicy prawns are hand-coated in a light, golden breadcrumb batter, then perfectly fried to create a satisfying crunch with every bite. Each nugget is seasoned with a blend of aromatic herbs and spices, adding a burst of savory flavor to the sweet, succulent prawns inside. Served with a side of tangy cocktail sauce or a zesty lemon aioli for dipping, these prawn nuggets are a delightful snack or appetizer that promises a burst of ocean-fresh goodness. Perfectly paired with a crisp salad or enjoyed on their own, they’re a true seafood lover’s delight."
            },
            {
                discount: "-25%",
                featured: false,
                img: prawnfries,
                stock: "In Stock",
                productName: "Prawn Fries",
                mainAmount: 89.00,
                subAmount: 159.00,
                type: kilo,
                para: "Elevate your snacking game with our Prawn Fries - a playful twist on classic fries with a seafood flair. Succulent, marinated prawns are wrapped in a crispy, golden batter and fried to perfection, creating a delightful crunch with every bite. Served alongside perfectly crisp, golden French fries, this dish is a fusion of flavors and textures that’s both satisfying and indulgent. Each prawn is seasoned with a touch of sea salt and a hint of paprika for extra zing, while the fries provide the perfect balance of salty, crispy goodness. Accompanied by your choice of tangy dipping sauces, such as spicy mayo or garlic aioli, these Prawn Fries are a deliciously unique treat that’s perfect for sharing—or not!"
            },
            // {
            //     discount: "-33%",
            //     featured: false,
            //     img: giantTigerShrimp,
            //     stock: "In Stock",
            //     productName: "Giant Tiger Shrimp",
            //     mainAmount: 400.00,
            //     subAmount: 500.00,
            //     type: kilo,
            //     para: "Females can reach about 33 cm (13 in) long, but are typically 25–30 cm (10–12 in) long and weigh 200–320 g (7–11 oz); males are slightly smaller at 20–25 cm (8–10 in) long and weighing 100–170 g (3.5–6.0 oz). The carapace and abdomen are transversely banded with alternative red and white."
            // }
        ],
        crab: [
            {
                discount: "-14%",
                featured: true,
                img: friedcrub,
                stock: "In Stock",
                productName: "Crab Fries",
                mainAmount: 99.00,
                subAmount: 159.00,
                type: kilo,
                para: "Elevate your fry game with our Crab Fries - a decadent fusion of savory and seafood flavors. Crispy, golden French fries are topped with generous portions of tender, sweet crab meat, seasoned to perfection with a blend of aromatic herbs and spices. Each bite offers a delightful mix of crispy fries and succulent crab, complemented by a drizzle of rich, creamy garlic aioli. Garnished with a sprinkle of fresh chives and a hint of lemon zest, these Crab Fries deliver a burst of ocean-fresh flavor and indulgence. Served with a side of tangy cocktail sauce or zesty lemon mayo, they’re the perfect shareable dish for any seafood lover or as a sumptuous treat for yourself."
            },
            {
                discount: "-14%",
                featured: true,
                img: crubcake,
                stock: "In Stock",
                productName: "Crab Cake",
                mainAmount: 120.00,
                subAmount: 170.00,
                type: kilo,
                para: "Savor the taste of the ocean with our delectable Crab Cake. Crafted from the finest, hand-picked crab meat, this cake is seasoned with a blend of fresh herbs and spices, then gently bound with a touch of breadcrumbs and a hint of Dijon mustard. Each bite offers a delightful combination of tender, sweet crab and a crispy, golden-brown exterior. Served with a zesty remoulade or tangy lemon aioli, the crab cake is garnished with a sprinkle of fresh parsley and a wedge of lemon for a burst of brightness. Whether enjoyed on its own, atop a bed of mixed greens, or as part of a gourmet sandwich, our Crab Cake promises a truly satisfying seafood experience."
            },
            {
                discount: "-25%",
                featured: false,
                img: crabpakora,
                stock: "In Stock",
                productName: "Crab pakora",
                mainAmount: 89.00,
                subAmount: 169.00,
                type: kilo,
                para: "Delight in the crispy, flavorful fusion of our Crab Pakora. Succulent chunks of tender crab meat are delicately seasoned with a blend of aromatic spices and herbs, then enveloped in a spiced chickpea flour batter. Each piece is fried to a golden crisp, creating a satisfying crunch that gives way to the sweet and juicy crab inside. Served with a side of tangy tamarind chutney and a refreshing mint-coriander dip, these pakoras offer a perfect balance of spice and flavor. Garnished with a sprinkle of chopped cilantro and a squeeze of lemon, our Crab Pakora makes for an irresistible appetizer or snack that brings a touch of exotic flair to any meal."
            },
            // {
            //     discount: "-33%",
            //     featured: false,
            //     img: seaCrab,
            //     stock: "In Stock",
            //     productName: "Sea Crab",
            //     mainAmount: 120.00,
            //     subAmount: 170.00,
            //     type: kilo,
            //     para: "Sea crabs are crustaceans that don't have a backbone and are protected by a hard shell. Their pincers are used catch food and to ﬁght off predators. The thousands of sea crab species can be found in oceans all around the world."
            // },
            // {
            //     discount: "-14%",
            //     featured: true,
            //     img: stoneCrab,
            //     stock: "In Stock",
            //     productName: "Stone Crab",
            //     mainAmount: 120.00,
            //     subAmount: 200.00,
            //     type: kilo,
            //     para: "Stone Crab can be found in both shallow, nearshore waters as well as deep, offshore benthic environments. Their preferred habitats include sandy/muddy bottoms, rocky outcrops, and seagrass beds."
            // }
        ],
        squid: [
            {
                discount: "-50%",
                featured: false,
                img: squidfries,
                stock: "In Stock",
                productName: "Squid Fries",
                mainAmount: 99.00,
                subAmount: 189.00,
                type: kilo,
                para: "Discover a new favorite with our Squid Fries - an inventive take on classic fries with a tantalizing seafood twist. Tender squid strips are lightly seasoned, then coated in a crispy, golden batter and fried to perfection. The result is a delightful crunch on the outside and a tender, flavorful bite on the inside. Served alongside a heap of golden French fries, this dish offers a playful and delicious fusion of textures. Each squid fry is seasoned with a hint of sea salt and a dash of paprika, adding a touch of flavor to every mouthful. Complemented by a choice of zesty dipping sauces, such as lemon aioli or spicy marinara, these Squid Fries make for an irresistible snack or appetizer that’s sure to impress."
            },
            {
                discount: "-50%",
                featured: false,
                img: squidkebab,
                stock: "In Stock",
                productName: "Squid Kebab",
                mainAmount: 99.00,
                subAmount: 199.00,
                type: kilo,
                para: "Savor the smoky, succulent flavors of our Squid Kebab. Fresh, tender squid pieces are marinated in a zesty blend of garlic, lemon, and aromatic herbs, then skewered and grilled to perfection. Each bite reveals a juicy interior with a delightful charred exterior, offering a burst of Mediterranean-inspired flavors. Garnished with a sprinkle of fresh cilantro and served with a side of cooling tzatziki sauce, this kebab is a harmonious blend of savory and tangy notes. Accompanied by warm pita bread and a refreshing salad, our Squid Kebab makes for an appetizing and satisfying meal that brings a taste of the grill to your plate."
            },
            {
                discount: "-50%",
                featured: false,
                img: squidburger,
                stock: "In Stock",
                productName: "Squid Burger",
                mainAmount: 99.00,
                subAmount: 159.00,
                type: kilo,
                para: "Experience a unique twist on the classic burger with our Squid Burger. Tender, grilled squid is seasoned with a blend of zesty spices and herbs, then placed on a lightly toasted brioche bun. The squid is topped with crisp lettuce, juicy tomato slices, and a creamy, tangy aioli that perfectly complements the seafood’s delicate flavor. For an extra burst of freshness, a few slices of pickled cucumber add a delightful crunch. Garnished with a sprinkle of fresh chives and a hint of lemon zest, this burger offers a sophisticated take on a beloved classic. Served with a side of crispy fries or a fresh mixed salad, our Squid Burger is a flavorful and satisfying choice that’s sure to impress."
            },
            {
                discount: "-34%",
                featured: false,
                img: squidsandwich,
                stock: "In Stock",
                productName: "Squid Sandwich",
                mainAmount: 99.00,
                subAmount: 169.00,
                type: kilo,
                para: "Dive into a flavorful ocean-inspired delight with our Squid Sandwich. Tender, marinated squid is grilled to perfection, offering a delightful combination of smoky char and succulent tenderness. Nestled in a freshly baked ciabatta roll, the squid is complemented by crisp arugula, ripe tomato slices, and a spread of zesty lemon-caper mayo that adds a tangy twist. A sprinkle of chili flakes provides a touch of heat, while a drizzle of balsamic glaze adds a subtle sweetness. Each bite is a harmonious blend of textures and flavors, making this sandwich a unique and satisfying choice for seafood lovers. Served with a side of golden, crispy chips or a refreshing side salad, our Squid Sandwich is a standout option for a memorable meal."
            },
            {
                discount: "-50%",
                featured: false,
                img: friedsquid,
                stock: "In Stock",
                productName: "Fried squid",
                mainAmount: 99.00,
                subAmount: 189.00,
                type: kilo,
                para: "Indulge in a crispy and flavorful treat with our Fried Squid. Tender rings of squid are lightly coated in a seasoned flour mixture and fried to a golden perfection, creating a satisfying crunch with every bite. Each piece is delicately spiced with a blend of paprika and garlic, enhancing the squid's natural sweetness. Served with a side of tangy dipping sauces, such as spicy marinara or lemon aioli, this dish offers a delightful contrast of crispy exterior and tender interior. Garnished with a sprinkle of fresh parsley and a wedge of lemon, our Fried Squid makes for a perfect appetizer or snack that promises a burst of flavor in every bite."
            },
            {
                discount: "-50%",
                featured: false,
                img: squidnuggets,
                stock: "In Stock",
                productName: "Squid Nuggets",
                mainAmount: 99.00,
                subAmount: 189.00,
                type: kilo,
                para: "Enjoy a fun and flavorful twist on a classic with our Squid Nuggets. Succulent pieces of squid are carefully seasoned and coated in a light, crispy batter before being fried to a golden perfection. Each nugget offers a satisfying crunch on the outside and tender, juicy squid on the inside. Infused with a hint of garlic and a touch of lemon zest, these nuggets are both savory and slightly tangy. Accompanied by your choice of dipping sauces, such as a tangy lemon aioli or a spicy sriracha mayo, our Squid Nuggets are the perfect snack or appetizer for seafood lovers seeking a delightful, bite-sized treat. Garnished with a sprinkle of fresh herbs and a wedge of lemon for added zest, they’re sure to be a hit at any gathering."
            }
        ],
        // pickles_frozenItems: [
        //     {
        //         discount: "-34%",
        //         featured: false,
        //         img: fishPickle,
        //         stock: "In Stock",
        //         productName: `Fish Pickle ${gram100}`,
        //         mainAmount: 104.00,
        //         subAmount: 120.00,
        //         para: "Fish pickle is a delicious and tangy condiment made by marinating pieces of fish in a spicy mixture of vinegar, spices, and oil. This traditional delicacy, popular in many coastal regions, combines the rich flavors of fresh fish with the aromatic intensity of Indian spices."
        //     },
        //     {
        //         discount: "-34%",
        //         featured: false,
        //         img: fishKebabMasala,
        //         stock: "In Stock",
        //         productName: `Fish Kebab Masala ${gram}`,
        //         mainAmount: 29.00,
        //         subAmount: 58.00,
        //         para: "Fish Kebab Masala is a versatile and aromatic spice blend specifically crafted to elevate the flavors of fish kebabs. This masala mix combines a rich array of spices and herbs, offering a harmonious balance of heat, tang, and savory notes that infuse your fish kebabs with an irresistible taste."
        //     }, {
        //         discount: "-34%",
        //         featured: false,
        //         img: fishKulambu,
        //         stock: "In Stock",
        //         productName: `Fish Kulambu Masala ${gram100}`,
        //         mainAmount: 30.00,
        //         subAmount: 40.00,
        //         para: "Fish Kolambu Masala is a robust and aromatic spice blend specifically crafted to enhance the flavors of traditional South Indian fish curries. This masala mix combines an array of spices and herbs, providing a perfect balance of heat, tang, and aromatic depth that infuses your fish kolambu with an authentic and delectable taste."
        //     },
        //     {
        //         discount: "-34%",
        //         featured: false,
        //         img: fish_65,
        //         stock: "In Stock",
        //         productName: `Fish 65 Masala ${gram}`,
        //         mainAmount: 59.00,
        //         subAmount: 100.00,
        //         para: "Fish 65 Masala is a specially crafted spice blend designed to create the iconic Fish 65, a popular South Indian appetizer. This masala mix combines a medley of spices and herbs, delivering a perfect balance of heat, tanginess, and savory notes that infuse your fish with a delectable and crispy coating."
        //     }
        // ],
        // driedSeaFood: [
        //     {
        //         discount: "-34%",
        //         featured: false,
        //         img: driedAnchovy,
        //         stock: "In Stock",
        //         productName: "Dreid Anchovies",
        //         mainAmount: 500.00,
        //         subAmount: 700.00,
        //         type: kilo,
        //         para: "These small, silvery ﬁsh are typically sun-dried or sometimes oven-dried until they become hard and brittle. Drying anchovies not only extends their shelf life but also intensiﬁes their unique umami ﬂavor."
        //     },
        //     {
        //         discount: "-50%",
        //         featured: false,
        //         img: driedShankara,
        //         stock: "In Stock",
        //         productName: "Dried Shankara fish",
        //         mainAmount: 400.00,
        //         subAmount: 700.00,
        //         type: kilo,
        //         para: "Sankara ﬁsh, also known as Red snapper in English, is a top seafood favourite. Sankara is a popular ﬁsh with a ﬁrm texture, a distinctly sweet ﬂavour, and an imprssive nutritional proﬁle."
        //     },
        //     {
        //         discount: "-14%",
        //         featured: true,
        //         img: driedShrimp,
        //         stock: "In Stock",
        //         productName: "Dried Baby shrimp",
        //         mainAmount: 450.00,
        //         subAmount: 800.00,
        //         type: kilo,
        //         para: "Dried shrimp are shrimp that have been sun-dried and shrunk to a thumbnail size. They are used in many East Asian, Southeast Asian and South Asian cuisines, imparting a unique umami taste."
        //     },
        //     {
        //         discount: "-25%",
        //         featured: false,
        //         img: driedShell,
        //         stock: "In Stock",
        //         productName: "Dried Shell",
        //         mainAmount: 600.00,
        //         subAmount: 900.00,
        //         type: kilo,
        //         para: "The shell itself is the exoskeleton of a soft-bodied mollusk. Composed mainly of calcium carbonate, the shell is secreted by the animal and provides protection as well as support for various organs"
        //     },
        //     {
        //         discount: "-33%",
        //         featured: false,
        //         img: driedCroker,
        //         stock: "In Stock",
        //         productName: "Dried croaker fish",
        //         mainAmount: 400.00,
        //         subAmount: 600.00,
        //         type: kilo,
        //         para: "Dried Croaker Fish contains many essential nutrients, such as omega-3 fatty acids and high quality proteins. Croaker ﬁsh can be processed into many delicious dishes, but usually be dried because it has a strong taste, fast processing and convenient to use."
        //     },
        //     {
        //         discount: "-14%",
        //         featured: true,
        //         img: driedMackrelFish,
        //         stock: "In Stock",
        //         productName: "Dried Mackrel Fish",
        //         mainAmount: 300.00,
        //         subAmount: 500.00,
        //         type: kilo,
        //         para: "Dry Mackerel Fish is salt-cured and dried mackerel ﬁsh. It is either sun-dried or dried in a food dehydrator until it is completely dry."
        //     },
        //     {
        //         discount: "-33%",
        //         featured: false,
        //         img: driedPrawn,
        //         stock: "In Stock",
        //         productName: "Dried Prawn",
        //         mainAmount: 500.00,
        //         subAmount: 600.00,
        //         type: kilo,
        //         para: "Tasty Nibbles Dried Prawns are wild caught Prawns that have been hygienically dried and shrunk to a thumbnail size. They are used in many Indian, East Asian, Southeast Asian, and South Asian cuisines, unique umami taste."
        //     },
        //     {
        //         discount: "-14%",
        //         featured: true,
        //         img: driedEel,
        //         stock: "In Stock",
        //         productName: "Dried eel fish",
        //         mainAmount: 300.00,
        //         subAmount: 500.00,
        //         type: kilo,
        //         para: "Eels are catadromous, meaning they primarily live in rivers and estuaries, but migrate out to the ocean - the Sargasso Sea, to spawn. American eels are an elongated ﬁsh, with fairly small ﬁns."
        //     },
        //     {
        //         discount: "-14%",
        //         featured: true,
        //         img: driedBombayDuckFish,
        //         stock: "In Stock",
        //         productName: "Dried bombay duck fish",
        //         mainAmount: 300.00,
        //         subAmount: 500.00,
        //         type: kilo,
        //         para: "Bombay duck ﬁsh of the family Synodontidae, found in estuaries of northern India, where it is widely used as a food ﬁsh and, when dried,or brown in colour with small, dark speckles."
        //     }
        // ],
    }

    const mapProductValue = productList[propsProductValue];

    // Convert to modal content

    const [moveContent, setMoveContent] = useState([]);

    return (
        <div>
            <div className="product_div_overall" style={{ paddingBottom: "30px" }}>
                {mapProductValue.map((product) => (
                    <div className="product_div">
                        <div className="product_discount_div">
                            <div>
                                <span className="product_dicount">{product.discount}</span>
                                <span className={product.featured === true ? "product_featured" : "product_featured_none"}>Featured</span>
                            </div>
                            <div className="product_cart_div">
                                <div>
                                    <button className="product_heart" custom_tooltip="Upcoming">
                                        <i class="fa-regular fa-heart"></i>
                                    </button>
                                </div>
                                <div>
                                    <button className="product_eye" custom_tooltip="Quick View" onClick={() => { setMoveContent(product) }} type="button" data-bs-toggle="modal" data-bs-target="#exampleModalAllProduct">
                                        <i class="fa-regular fa-eye"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="modal fade" id="exampleModalAllProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-xl" >
                                    <div class="modal-content" id="product_modal_width">
                                        <div class="modal-body" id="product_modal_content_overall">
                                            <div className="productTwo_closeMob">
                                                <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                                    <i class="fa-solid fa-xmark fa-lg"></i>
                                                </button>
                                            </div>
                                            <div className="product_modal_one">
                                                <img src={moveContent.img} alt="product_img"></img>
                                            </div>
                                            <div className="product_modal_two">
                                                <div className="productTwo_title">
                                                    <div>
                                                        <h4 className="productTwo_title_size">{moveContent.productName}</h4>
                                                    </div>
                                                    <div className="productTwo_closeDesktop">
                                                        <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                                            <i class="fa-solid fa-xmark fa-lg"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="productTwo_rateDiv">
                                                        <div className="product_rate">
                                                            <span className="productTwo_main_rate">₹ {moveContent.mainAmount} {moveContent.type}</span>
                                                            <span className="productTwo_sub_rate">₹{moveContent.subAmount}</span>
                                                        </div>
                                                        <div className="productTwo_stock_gap">
                                                            <span className={moveContent.stock === "In Stock" ? "productStock_update" : "productOutOf_stock_update"} >
                                                                <span id="productTwo_dot">•</span> {moveContent.stock === "In Stock" ? "In Stock" : "Out Of Stock"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="productTwo_contentDiv">
                                                    <p className="productTwo_para">
                                                        {moveContent.para}
                                                    </p>
                                                </div>
                                                <div className="productTwo_BuyNow">
                                                    <button className="productTwo_enquiryBtn" onClick={handleChatSubmit}>
                                                        <i class="fa-solid fa-phone" style={{ color: "#ffffff", fontSize: "13.5px" }}></i>
                                                        <span style={{ marginLeft: "6px" }}>Buy Now</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="product_img_div">
                                <img className="product_img" src={product.img} alt="demo" />
                            </div>
                        </div>
                        <div>
                            <div >
                                <ul className={product.stock === "In Stock" ? "stock_update" : "outOf_stock_update"} >
                                    <li>{product.stock === "In Stock" ? "In Stock" : "Out Of Stock"}</li>
                                </ul>
                            </div>
                            <div className="product_rate">
                                <Link className="product_name_link">{product.productName}</Link>
                            </div>
                            <div className="product_rate">
                                <span className="product_main_rate">₹ {product.mainAmount} {product.type}</span>
                                <span className="product_sub_rate">₹{product.subAmount}</span>
                            </div>
                        </div>

                    </div>
                ))
                }
            </div>
        </div>
    )
}

export const HotDeals = ({ time }) => {

    const kilo = " /-";

    // const gram = "- (50 g)";

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        rtl: true,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3.8,
                }
            },
            {
                breakpoint: 1168,
                settings: {
                    slidesToShow: 3.4,
                }
            }
            ,
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 924,
                settings: {
                    slidesToShow: 2.8,
                }
            }
            ,
            {
                breakpoint: 924,
                settings: {
                    slidesToShow: 2.8,
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2.8,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 490,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const productList = [
        {
            discount: "-14%",
            featured: true,
            img: friedcatfish,
            stock: "In Stock",
            productName: "Fish Oil Fry ",
            mainAmount: 69.00,
            subAmount: 159.00,
            type: kilo,
            para: "Indulge in the crispy delight of our Fish Oil Fry, where succulent pieces of fresh fish are expertly coated in a seasoned batter and fried to golden perfection. The result is a crunchy exterior that gives way to tender, flaky fish inside. Lightly drizzled with a touch of fish oil for a hint of rich, savory depth, this dish is served with a zesty dipping sauce and a sprinkle of fresh herbs. Perfect as a snack or a main course, it’s a deliciously satisfying treat that combines simplicity with exceptional flavor."
        },
        {
            discount: "-50%",
            featured: false,
            img: fishsandwich,
            stock: "In Stock",
            productName: "Fish sandwich",
            mainAmount: 49.00,
            subAmount: 119.00,
            type: kilo,
            para: "Topped with crisp lettuce, juicy tomatoes, and our signature tartar sauce, this sandwich is a harmonious blend of flavors and textures. Ideal for a quick lunch or a delicious dinner, the Seaboss Fish Sandwich offers a mouthwatering seafood experience that's both wholesome and indulgent. Enjoy it as a classic or customize with your favorite toppings for a sandwich that’s uniquely yours!."
        },
        {
            discount: "-14%",
            featured: true,
            img: fishpopcorn,
            stock: "In Stock",
            productName: "Fish Popcorn",
            mainAmount: 59.00,
            subAmount: 199.00,
            type: kilo,
            para: "Delight in the fun and flavor of our Fish Popcorn, where tender pieces of premium fish are seasoned, lightly breaded, and fried to a golden, crispy finishPerfectly paired with your favorite dipping sauces, our Fish Popcorn is a versatile and irresistible treat that’s sure to please seafood lovers of all ages. Enjoy these crunchy, savory bites as a quick snack or an appetizing party starter!."
        },
        {
            discount: "-14%",
            featured: true,
            img: prawnnuggets,
            stock: "In Stock",
            productName: "Prawn Nuggets",
            mainAmount: 99.00,
            subAmount: 199.00,
            type: kilo,
            para: "Indulge in a crispy, flavorful treat with our Prawn Nuggets. Tender, juicy prawns are hand-coated in a light, golden breadcrumb batter, then perfectly fried to create a satisfying crunch with every bite. Each nugget is seasoned with a blend of aromatic herbs and spices, adding a burst of savory flavor to the sweet, succulent prawns inside. Served with a side of tangy cocktail sauce or a zesty lemon aioli for dipping, these prawn nuggets are a delightful snack or appetizer that promises a burst of ocean-fresh goodness. Perfectly paired with a crisp salad or enjoyed on their own, they’re a true seafood lover’s delight."
        },
        {
            discount: "-14%",
            featured: true,
            img: fishchips,
            stock: "In Stock",
            productName: "Fish Chips",
            mainAmount: 49.00,
            subAmount: 159.00,
            type: kilo,
            para: "Dive into a classic favorite with our Fish and Chips, featuring tender, flaky white fish coated in a golden, crispy batter. Each piece is cooked to perfection, served alongside perfectly crisped fries that are lightly seasoned for maximum flavor. Accompanied by a side of tangy tartar sauce and a wedge of fresh lemon, this dish offers a timeless taste of comfort and tradition. Enjoy a taste of the coast with every bite!."
        },
        {
            discount: "-50%",
            featured: false,
            img: friedsquid,
            stock: "In Stock",
            productName: "Fried squid",
            mainAmount: 99.00,
            subAmount: 189.00,
            type: kilo,
            para: "Indulge in a crispy and flavorful treat with our Fried Squid. Tender rings of squid are lightly coated in a seasoned flour mixture and fried to a golden perfection, creating a satisfying crunch with every bite. Each piece is delicately spiced with a blend of paprika and garlic, enhancing the squid's natural sweetness. Served with a side of tangy dipping sauces, such as spicy marinara or lemon aioli, this dish offers a delightful contrast of crispy exterior and tender interior. Garnished with a sprinkle of fresh parsley and a wedge of lemon, our Fried Squid makes for a perfect appetizer or snack that promises a burst of flavor in every bite."
        },
        {
            discount: "-34%",
            featured: false,
            img: prawnburger,
            stock: "In Stock",
            productName: "Prawn Burger",
            mainAmount: 99.00,
            subAmount: 159.00,
            type: kilo,
            para: "Dive into a seafood sensation with our Prawn Burger. Juicy, tender prawns are seasoned with a blend of zesty herbs and spices, then gently folded into a savory patty that's grilled to perfection. Nestled in a lightly toasted brioche bun, the prawn patty is topped with crisp lettuce, ripe tomatoes, and a tangy aioli sauce that adds a creamy kick. Finished with a hint of lemon zest and a sprinkle of fresh chives, this burger offers a delightful burst of flavors with every bite. Served with a side of golden fries or a fresh salad, it's a true taste of the ocean on a bun!."
        }
    ]

    // Convert to modal content

    const whatsappNumber = "6366192865";

    const whatsappUrl = `https://wa.me/${whatsappNumber}`;

    const handleChatSubmit = (e) => {
        e.preventDefault();
        window.open(whatsappUrl, "_blank")
    }

    const [moveContent, setMoveContent] = useState([]);

    return (
        <div>
            <div className="hotDeal_title">
                <div className="hotDeal_inner_title">
                    <h5 className="hotDeal_title_content">Hot Deals!</h5>
                    <h6 className="hotDeal_subtitle_content">Remains until the end of the offer</h6>
                </div>
                <div>
                    <div className="hotDeal_timer_overall">
                        <div>
                            <span className="hotDeal_time">{time.startDay}</span>
                        </div>
                        <div>
                            <span className="hotDeal_time_dot">:</span>
                        </div>
                        <div>
                            <span className="hotDeal_time">{time.startHours}</span>
                        </div>
                        <div>
                            <span>:</span>
                        </div>
                        <div>
                            <span className="hotDeal_time">{time.startMinutes}</span>
                        </div>
                        <div>
                            <span>:</span>
                        </div>
                        <div>
                            <span className="hotDeal_time">{time.startSeconds}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slider-container">
                <Slider {...settings}>
                    {productList.map((product) => (
                        <div id="hotDeal_div_overall">
                            <div className="product_div">
                                <div className="product_discount_div">

                                    <div>
                                        <span className="product_dicount">{product.discount}</span>
                                        <span className={product.featured === true ? "product_featured" : "product_featured_none"}>Featured</span>
                                    </div>

                                    <div className="product_cart_div">
                                        <div>
                                            <button className="product_heart" custom_tooltip="Upcoming">
                                                <i class="fa-regular fa-heart"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <button className="product_eye" custom_tooltip="Quick View" onClick={() => { setMoveContent(product) }} type="button" data-bs-toggle="modal" data-bs-target="#modalHotDeals">
                                                <i class="fa-regular fa-eye"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div className="product_img_div">
                                        <img className="product_img" src={product.img} alt="demo" />
                                    </div>
                                </div>

                                <div>
                                    <div >
                                        <ul className={product.stock === "In Stock" ? "stock_update" : "outOf_stock_update"} >
                                            <li>{product.stock === "In Stock" ? "In Stock" : "Out Of Stock"}</li>
                                        </ul>
                                    </div>
                                    <div className="product_name_div">
                                        <Link className="product_name_link">{product.productName}</Link>
                                    </div>
                                    <div className="product_rate">
                                        <span className="product_main_rate">₹ {product.mainAmount} {product.type}</span>
                                        <span className="product_sub_rate">₹{product.subAmount}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))
                    }
                </Slider>

                <div class="modal fade" id="modalHotDeals" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" >
                        <div class="modal-content" id="product_modal_width">
                            <div class="modal-body" id="product_modal_content_overall">
                                <div className="productTwo_closeMob">
                                    <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fa-solid fa-xmark fa-lg"></i>
                                    </button>
                                </div>
                                <div className="product_modal_one">
                                    <img src={moveContent.img} alt="product_img"></img>
                                </div>
                                <div className="product_modal_two">
                                    <div className="productTwo_title">
                                        <div>
                                            <h4 className="productTwo_title_size">{moveContent.productName}</h4>
                                        </div>
                                        <div className="productTwo_closeDesktop">
                                            <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                                <i class="fa-solid fa-xmark fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="productTwo_rateDiv">
                                            <div className="product_rate">
                                                <span className="productTwo_main_rate">₹ {moveContent.mainAmount} {moveContent.type}</span>
                                                <span className="productTwo_sub_rate">₹{moveContent.subAmount}</span>
                                            </div>
                                            <div className="productTwo_stock_gap">
                                                <span className={moveContent.stock === "In Stock" ? "productStock_update" : "productOutOf_stock_update"} >
                                                    <span id="productTwo_dot">•</span> {moveContent.stock === "In Stock" ? "In Stock" : "Out Of Stock"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productTwo_contentDiv">
                                        <p className="productTwo_para">
                                            {moveContent.para}
                                        </p>
                                    </div>
                                    <div className="productTwo_BuyNow">
                                        <button className="productTwo_enquiryBtn" onClick={handleChatSubmit}>
                                            <i class="fa-solid fa-phone" style={{ color: "#ffffff", fontSize: "13.5px" }}></i>
                                            <span style={{ marginLeft: "6px" }}>Buy Now</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const PropularProduct = () => {

    const kilo = " /-";

    // const gram = "- (50 g)";

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        // autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3.8,
                }
            },
            {
                breakpoint: 1168,
                settings: {
                    slidesToShow: 3.4,
                }
            }
            ,
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 924,
                settings: {
                    slidesToShow: 2.8,
                }
            }
            ,
            {
                breakpoint: 924,
                settings: {
                    slidesToShow: 2.8,
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2.8,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 490,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const productList = [
        {
            discount: "-14%",
            featured: true,
            img: fishtikka,
            stock: "In Stock",
            productName: "Tandoori Fish Tikka",
            mainAmount: 69.00,
            subAmount: 159.00,
            type: kilo,
            para: "Experience a burst of vibrant flavors with our Tandoori Fish Tikka. Tender chunks of fresh fish are marinated in a blend of aromatic spices, yogurt, and a hint of tangy lemon juice, creating a rich, flavorful coating. Grilled to perfection in a traditional tandoor oven, each bite offers a smoky char and a succulent, juicy texture. Served with a side of cooling mint chutney and crisp, refreshing salad, this dish is a tantalizing fusion of spice and taste that will transport your taste buds straight to the heart of Indian cuisine."
        },
        // {
        //     discount: "-50%",
        //     featured: false,
        //     img: northernPrawn,
        //     stock: "In Stock",
        //     productName: "Northern Prawn",
        //     mainAmount: 200.00,
        //     subAmount: 250.00,
        //     type: kilo,
        //     para: "The Northern prawn is a hermaphrodite and starts its life as a male after 4-5 years it turns female. The species is found mostly in areas with soft, muddy sediment where temperatures range from 1-6°C at a depth between 150 and 600 meters."
        // },
        {
            discount: "-14%",
            featured: true,
            img: tawafishfry,
            stock: "In Stock",
            productName: "Tawa Fishfry",
            mainAmount: 99.00,
            subAmount: 159.00,
            type: kilo,
            para: "Each bite delivers a delightful crunch and a burst of savory, spiced goodness that captures the essence of authentic seafood cuisine. With its golden, crispy exterior and juicy, flavorful interior, our Tawa Fish Fry is perfect for a satisfying meal or an appetizing snack. Enjoy it with a side of fresh salad or your favorite dipping sauce for a taste sensation that’s truly unforgettable!."
        },
        {
            discount: "-50%",
            featured: false,
            img: squidkebab,
            stock: "In Stock",
            productName: "Squid Kebab",
            mainAmount: 99.00,
            subAmount: 199.00,
            type: kilo,
            para: "Savor the smoky, succulent flavors of our Squid Kebab. Fresh, tender squid pieces are marinated in a zesty blend of garlic, lemon, and aromatic herbs, then skewered and grilled to perfection. Each bite reveals a juicy interior with a delightful charred exterior, offering a burst of Mediterranean-inspired flavors. Garnished with a sprinkle of fresh cilantro and served with a side of cooling tzatziki sauce, this kebab is a harmonious blend of savory and tangy notes. Accompanied by warm pita bread and a refreshing salad, our Squid Kebab makes for an appetizing and satisfying meal that brings a taste of the grill to your plate."
        },
        {
            discount: "-50%",
            featured: false,
            img: squidburger,
            stock: "In Stock",
            productName: "Squid Burger",
            mainAmount: 99.00,
            subAmount: 159.00,
            type: kilo,
            para: "Experience a unique twist on the classic burger with our Squid Burger. Tender, grilled squid is seasoned with a blend of zesty spices and herbs, then placed on a lightly toasted brioche bun. The squid is topped with crisp lettuce, juicy tomato slices, and a creamy, tangy aioli that perfectly complements the seafood’s delicate flavor. For an extra burst of freshness, a few slices of pickled cucumber add a delightful crunch. Garnished with a sprinkle of fresh chives and a hint of lemon zest, this burger offers a sophisticated take on a beloved classic. Served with a side of crispy fries or a fresh mixed salad, our Squid Burger is a flavorful and satisfying choice that’s sure to impress."
        },
        {
            discount: "-25%",
            featured: false,
            img: prawnfries,
            stock: "In Stock",
            productName: "Prawn Fries",
            mainAmount: 89.00,
            subAmount: 159.00,
            type: kilo,
            para: "Elevate your snacking game with our Prawn Fries - a playful twist on classic fries with a seafood flair. Succulent, marinated prawns are wrapped in a crispy, golden batter and fried to perfection, creating a delightful crunch with every bite. Served alongside perfectly crisp, golden French fries, this dish is a fusion of flavors and textures that’s both satisfying and indulgent. Each prawn is seasoned with a touch of sea salt and a hint of paprika for extra zing, while the fries provide the perfect balance of salty, crispy goodness. Accompanied by your choice of tangy dipping sauces, such as spicy mayo or garlic aioli, these Prawn Fries are a deliciously unique treat that’s perfect for sharing—or not!."
        },
        {
            discount: "-14%",
            featured: true,
            img: fishcutlet,
            stock: "In Stock",
            productName: "Fish Cutlet",
            mainAmount: 59.00,
            subAmount: 159.00,
            type: kilo,
            para: "Enjoy the delectable taste of our Fish Cutlet, where tender chunks of high-quality fish are blended with a savory mix of herbs, spices, and breadcrumbs, then fried to a crispy, golden-brown finish. Each cutlet offers a delightful crunch on the outside and a juicy, flavorful interior, making it a perfect appetizer, snack, or light meal."
        },
    ]

    // Convert to modal content

    const whatsappNumber = "6366192865";

    const whatsappUrl = `https://wa.me/${whatsappNumber}`;

    const handleChatSubmit = (e) => {
        e.preventDefault();
        window.open(whatsappUrl, "_blank")
    }

    const [moveContent, setMoveContent] = useState([]);

    return (
        <div>
            <div className="hotDeal_title">
                <div className="hotDeal_inner_title">
                    <h5 className="hotDeal_title_content">Recommend Products</h5>
                    {/* <span className="hotDeal_subtitle_content"></span> */}
                </div>
                <div>
                    <Link to={"allProducts"} smooth={true} duration={500} className="view_all_btn">
                        <div>
                            <span>View All</span>
                        </div>
                        <div>
                            <i id="viewAll_arrow_icon" class="fa-solid fa-arrow-right-long"></i>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="slider-container">
                <Slider {...settings}>
                    {productList.map((product) => (
                        <div id="hotDeal_div_overall">
                            <div className="product_div">
                                <div className="product_discount_div">
                                    <div>
                                        <span className="product_dicount">{product.discount}</span>
                                        <span className={product.featured === true ? "product_featured" : "product_featured_none"}>Featured</span>
                                    </div>

                                    <div className="product_cart_div">
                                        <div>
                                            <button className="product_heart" custom_tooltip="Upcoming">
                                                <i class="fa-regular fa-heart"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <button className="product_eye" custom_tooltip="Quick View" onClick={() => { setMoveContent(product) }} type="button" data-bs-toggle="modal" data-bs-target="#modalRecommendProduct">
                                                <i class="fa-regular fa-eye"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="product_img_div">
                                        <img className="product_img" src={product.img} alt="demo" />
                                    </div>
                                </div>

                                <div>
                                    <div >
                                        <ul className={product.stock === "In Stock" ? "stock_update" : "outOf_stock_update"} >
                                            <li>{product.stock === "In Stock" ? "In Stock" : "Out Of Stock"}</li>
                                        </ul>
                                    </div>
                                    <div className="product_rate">
                                        <Link className="product_name_link">{product.productName}</Link>
                                    </div>
                                    <div className="product_rate">
                                        <span className="product_main_rate">₹ {product.mainAmount} {product.type
                                        }</span>
                                        <span className="product_sub_rate">₹{product.subAmount} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </Slider>

                <div class="modal fade" id="modalRecommendProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" >
                        <div class="modal-content" id="product_modal_width">
                            <div class="modal-body" id="product_modal_content_overall">
                                <div className="productTwo_closeMob">
                                    <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="fa-solid fa-xmark fa-lg"></i>
                                    </button>
                                </div>
                                <div className="product_modal_one">
                                    <img src={moveContent.img} alt="product_img"></img>
                                </div>
                                <div className="product_modal_two">
                                    <div className="productTwo_title">
                                        <div>
                                            <h4 className="productTwo_title_size">{moveContent.productName}</h4>
                                        </div>
                                        <div className="productTwo_closeDesktop">
                                            <button id="productTwo_close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                                <i class="fa-solid fa-xmark fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="productTwo_rateDiv">
                                            <div className="product_rate">
                                                <span className="productTwo_main_rate">₹ {moveContent.mainAmount} {moveContent.type}</span>
                                                <span className="productTwo_sub_rate">₹{moveContent.subAmount}</span>
                                            </div>
                                            <div className="productTwo_stock_gap">
                                                <span className={moveContent.stock === "In Stock" ? "productStock_update" : "productOutOf_stock_update"} >
                                                    <span id="productTwo_dot">•</span> {moveContent.stock === "In Stock" ? "In Stock" : "Out Of Stock"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productTwo_contentDiv">
                                        <p className="productTwo_para">
                                            {moveContent.para}
                                        </p>
                                    </div>
                                    <div className="productTwo_BuyNow">
                                        <button className="productTwo_enquiryBtn" onClick={handleChatSubmit}>
                                            <i class="fa-solid fa-phone" style={{ color: "#ffffff", fontSize: "13.5px" }}></i>
                                            <span style={{ marginLeft: "6px" }}>Buy Now</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}