import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './main_folder/pages/app';
// import { UnderConstruction } from './main_folder/pages/under_construction';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>    
    {/* <UnderConstruction/> */}
  </React.StrictMode>   
);

reportWebVitals();
